<template>
  <div>
    <div
      variant="light"
      class="justify-content-end container alert alert-white alert-shadow"
      style="padding-top: 10px"
    >
      <b-row>
        <b-col>
          <b-form-group label="Tên Merchant (*)">
            <b-form-input
              v-model="merchant.name"
              placeholder="Nhập tên merchant"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Merchant code (*)">
            <b-form-input
              v-model="merchant.code"
              placeholder="Nhập merchant code"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Merchant cha">
            <b-form-select
              v-model="merchant.parent_id"
              :options="option.merchantParent"
              value-field="id"
              text-field="name"
            >
              <option :value="null">-- Chọn merchant cha --</option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Loại Merchant (*)">
            <b-form-select
              v-model="merchant.type"
              :options="option.type"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Nhập Ipn">
            <b-form-input
              v-model="merchant.ipn"
              placeholder="Nhập Ipn"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="ID sản phẩm">
            <b-form-input
              v-model="merchant.product_id"
              placeholder="Nhập sản phẩm cách nhau bởi dấu phẩy. vd: 1,5,3,6,..."
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Mã mapping">
            <b-form-input v-model="merchant.type_sdk" placeholder="Mã mapping với cổng thanh toán"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Mô tả">
            <b-form-textarea
              id="textarea"
              v-model="merchant.description"
              placeholder="Mô tả"
              rows="2"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="card-footer" style="text-align: right">
        <button class="btn btn-primary mr-2" @click="storeMerchant()">
          Lưu
        </button>
        <button class="btn btn-secondary mr-2" @click="showList()">
          Thoát
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
const CmsRepository = RepositoryFactory.get("cms");

Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      fields: [
        { id: "ID tài khoản" },
        { name: "Tên tài khoản" },
        { balance: "Số dư" },
        { created_at: "Ngày tạo" },
        { options: "Tùy chọn" },
      ],
      merchant: {
        name: null,
        code: null,
        description: null,
        parent_id: null,
        product_id: null,
        ipn: null,
        type: "WALLET",
        type_sdk: null
      },
      option: {
        merchantParent: [],
        type: [
          { value: "WALLET", text: "Wallet" },
          { value: "GATEWAY", text: "Gateway" },
        ],
      },
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tạo mới Merchant", route: "merchant" },
    ]);
  },
  methods: {
    listMerchantParent() {
      CmsRepository.listMerchantParent()
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.option.merchantParent = response.data.data;
          }
        })
        .catch(() => {});
    },
    storeMerchant() {
      if (
        this.merchant.name === null ||
        this.merchant.code == null ||
        this.merchant.type === null
      ) {
        this.notifyAlert(
          "warn",
          "Tên, code và loại merchant không được bỏ trống"
        );
        return;
      }
      if (this.merchant.code.indexOf(" ") >= 0) {
        this.notifyAlert("warn", "Mã Code không được có khoảng trắng");
        return;
      }
      this.$bus.$emit("show-loading", true);
      let params = {
        name: this.merchant.name,
        code: this.merchant.code,
        type: this.merchant.type,
        type_sdk: this.merchant.type_sdk
      };
      if (this.merchant.parent_id !== null) {
        params.parent_id = this.merchant.parent_id;
      }
      if (this.merchant.ipn !== null && this.merchant.ipn !== "") {
        params.ipn = this.merchant.ipn;
      }
      if (this.merchant.product_id !== null) {
        params.product_id = this.merchant.product_id;
      }
      if (this.merchant.description !== null) {
        params.description = this.merchant.description;
      }
      params = this.removeValidateNull(params)
      CmsRepository.storeMerchant(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.$router.push("/account/merchant");
            this.$bus.$emit("show-loading", false);
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
      this.$bus.$emit("show-loading", false);
    },
    showList() {
      this.$router.push("/account/merchant");
    },
  },
  created() {
    this.listMerchantParent();
  },
  computed: {},
  watch: {},
};
</script>
